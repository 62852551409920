<template>
  <div
    class="flex flex-col h-full overflow-hidden relative rounded p-10"
    :class="[colors.bg, colors.text]"
    alt="Static"
    style="box-shadow: 0 10px 30px rgba(0,0,0, .05)"
  >
    <!-- <img
      :src="colors.static"
      class="absolute inset-0 min-h-full opacity-25 w-full"
      v-if="stat.status !== 'primary'"
    /> -->

    <div class="relative z-10">
      <div class="font-semibold mb-2 opacity-75 text-lg">
        {{ title }}
      </div>
      <div class="mb-5 opacity-50" v-if="!!stat.description">
        {{ stat.description }}
      </div>
      <div
        class="flex flex-wrap mb-5 opacity-50"
        v-if="stat.children && stat.children.length"
      >
        <template v-for="(child, index) in stat.children">
          <span :key="index" class="mb-1 mr-2">
            {{ child.title }}: {{ getValue(child) }}
            <template v-if="index < stat.children.length - 1">|</template>
          </span>
        </template>
      </div>
      <div class="flex flex-wrap items-center mt-auto">
        <span
          class="grid h-12 mr-5 place-items-center rounded-full text-2xl w-12"
          :class="[colors.iconBg, colors.iconText]"
        >
          <ion-icon :name="icon" />
        </span>
        <div>
          <span class="font-bold text-3xl">
            {{ value }}
          </span>
          <span class="font-bold opacity-50">{{ stat.unit }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    stat: {
      type: Object,
      default: () => null
    }
  },
  computed: {
    colors() {
      switch (this.stat?.status) {
        // case 'success':
        //   return {
        //     bg: 'bg-green-500',
        //     text: 'text-white',
        //     iconBg: 'bg-green-200',
        //     iconText: 'text-green-600',
        //     static: this.assets.icons.xl.staticBlack,
        //   };
        // case 'danger':
        //   return {
        //     bg: 'bg-red-500',
        //     text: 'text-white',
        //     iconBg: 'bg-red-200',
        //     iconText: 'text-red-600',
        //     static: this.assets.icons.xl.static,
        //   };
        case 'primary':
        default:
          return {
            bg: 'bg-white',
            text: 'text-current',
            iconBg: 'bg-blue-200',
            iconText: 'text-blue-600'
            // static: this.assets.icons.xl.staticBlack,
          };
      }
    },
    icon() {
      if (this.stat?.icon) return this.stat?.icon;
      switch (this.type) {
        case 'amount':
          return 'cash-outline';
        case 'count':
        default:
          return 'add';
      }
    },
    title() {
      const { fromSlug, sentenceCase } = this.$options.filters;
      if (!this.stat?.title) {
        return sentenceCase(fromSlug(this.stat?.key));
      }
      return this.stat?.title;
    },
    type() {
      return this.stat?.type ?? 'count';
    },
    value() {
      const { currency } = this.$options.filters;
      const value = this.stat?.value;
      switch (this.type) {
        case 'amount':
          return currency(value);
        case 'percentage':
          return value;
        case 'count':
        default:
          return value;
      }
    }
  },
  methods: {
    getValue(s) {
      const stat = s || this.stat;
      const { currency } = this.$options.filters;
      const value = stat?.value;
      switch (stat.type) {
        case 'amount':
          return currency(value);
        case 'percentage':
          return value;
        case 'count':
        default:
          return value;
      }
    }
  }
};
</script>
